import React from 'react';
import { Hoverable } from 'react-native-web-hooks';
import { View, Text, StyleSheet, Image, ImageURISource } from 'react-native';

import { useDimensions } from '../../context/DimensionsContext';
import { smMdBreakpoint } from '../../constants/breakpoints';
import { blue, GTAmericaCompressedRegular } from '../../constants/brand';
import { TouchableOpacity } from '../common/TouchableOpacity';

interface Props {
  url: string;
  quote: string;
  image: ImageURISource;
}

export const CreativityLink: React.FC<Props> = ({ url, quote, image }) => {
  const { width } = useDimensions();
  const styles = StyleSheet.create({
    quoteContainer: {
      marginTop: width < smMdBreakpoint ? 100 : 180,
      alignItems: 'center',
    },
    innerContainer: { alignItems: 'center' },

    quote: {
      fontSize: width < smMdBreakpoint ? 36 : 72,
      color: blue,
      fontFamily: GTAmericaCompressedRegular,
      letterSpacing: 1.3,
      textAlign: 'center',
      paddingHorizontal: width < smMdBreakpoint ? 20 : 80,
    },
    logo: {
      width: width < smMdBreakpoint ? 200 : 300,
      height: 100,
      resizeMode: 'contain',
      marginTop: width < smMdBreakpoint ? 10 : 25,
    },
  });
  return (
    <Hoverable>
      {(isHovered) => (
        <View
          style={[
            styles.quoteContainer,
            { transform: [{ scale: isHovered ? 1.05 : 1 }] },
          ]}
        >
          <TouchableOpacity
            onPress={() => {
              window.open(url, '__blank');
            }}
          >
            <View style={styles.innerContainer}>
              <Text style={styles.quote}>"{quote}"</Text>
              <Image style={styles.logo} source={image} />
            </View>
          </TouchableOpacity>
        </View>
      )}
    </Hoverable>
  );
};
