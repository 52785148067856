import React from 'react';
import { Linking, View, Text, Image, ImageURISource } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { useFonts } from '@use-expo/font';

import { blue, PanamaBold } from '../../constants/brand';
import { TouchableOpacity } from './TouchableOpacity';

interface Props {
  copy?: string;
  path: string;
  height?: number;
  width?: number;
  fontSize?: number;
  color?: string;
  image?: ImageURISource;
}

export const Button: React.FC<Props> = React.memo(({
  copy,
  path,
  width,
  height,
  fontSize,
  color,
  image,
}) => {
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../../assets/fonts/Panama-Bold.otf'),
  });
  if (!fontsLoaded) return null;

  return (
    <Hoverable>
      {(isHovered) => (
        <TouchableOpacity onPress={() => Linking.openURL(`${path}`)}>
          <View
            style={{
              borderWidth: 1,
              borderColor: color ? color : blue,
              borderRadius: 40,
              width: width ? width : 165,
              height: height ? height : 63,
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {copy && (
              <Text
                style={{
                  fontFamily: PanamaBold,
                  textTransform: 'uppercase',
                  fontSize: fontSize ? fontSize : 24,
                  letterSpacing: 1.3,
                  color: color ? color : blue,
                  paddingTop: 5,
                }}
              >
                {copy}
              </Text>
            )}
            {image && (
              <Image
                style={{ height: 60, width: 100, resizeMode: 'contain' }}
                source={image}
              />
            )}
          </View>
        </TouchableOpacity>
      )}
    </Hoverable>
  );
});
