import React from 'react';
import { View, Image, Text } from 'react-native';
import { useFonts } from '@use-expo/font';

import {
  orange,
  PanamaBold,
  white,
  PanamaRegular,
} from '../../constants/brand';
import { useDimensions } from '../../context/DimensionsContext';
import { Button } from '../common/Button';
import {
  mdBreakpoint,
  smMdBreakpoint,
  smBreakpoint,
  mdLgBreakpoint,
  lgBreakpoint,
} from '../../constants/breakpoints';

interface Props {}

export const HomeDreamTeam: React.FC<Props> = React.memo(() => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../../assets/fonts/Panama-Bold.otf'),
    'Panama-Regular': require('../../../assets/fonts/Panama-Regular.otf'),
  });

  if (!fontsLoaded) return null;

  return (
    <View>
      <Image
        style={{
          width,
          height: width < smMdBreakpoint ? 750 : '100vh',
          resizeMode: 'cover',
        }}
        source={require('../../../assets/images/scribbles.png')}
      />
      <View
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            opacity: 1,
            backgroundColor: orange,
            height:
              width < smBreakpoint
                ? '60%'
                : width < smMdBreakpoint
                ? '69%'
                : width < mdBreakpoint
                ? '50%'
                : '70%',
            width:
              width < smMdBreakpoint
                ? '85%'
                : width < lgBreakpoint
                ? '80%'
                : '70%',
            justifyContent: width < mdBreakpoint ? 'center' : 'space-evenly',
            flexDirection: width < mdBreakpoint ? 'column' : 'row',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <Image
            style={{
              height: width < mdBreakpoint ? '50%' : '80%',
              width: width < mdBreakpoint ? '60%' : '40%',
              resizeMode: 'contain',
            }}
            source={require('../../../assets/images/figure-drawing.png')}
          />
          <View
            style={{
              flexDirection: 'column',
              width:
                width < mdBreakpoint
                  ? '80%'
                  : width < mdLgBreakpoint
                  ? '40%'
                  : '50%',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontFamily: PanamaBold,
                textTransform: 'uppercase',
                fontSize:
                  width < mdBreakpoint
                    ? 24
                    : width < mdLgBreakpoint
                    ? 36
                    : width < lgBreakpoint
                    ? 42
                    : 48,
                color: white,
                letterSpacing: 1.3,
                marginBottom: width < mdBreakpoint ? 10 : 20,
                marginTop: width < mdBreakpoint ? 10 : 0,
              }}
            >
              Dream Team
            </Text>
            <Text
              style={{
                fontFamily: PanamaRegular,
                fontSize:
                  width < mdBreakpoint
                    ? 18
                    : width < mdLgBreakpoint
                    ? 24
                    : width < lgBreakpoint
                    ? 30
                    : 36,
                color: white,
                letterSpacing: 1.3,
                textAlign: 'center',
                marginBottom: width < mdBreakpoint ? 10 : 20,
              }}
            >
              We’ve assembled a team of the top creative and strategic minds to
              bring this brand to life.
            </Text>
            <Button
              fontSize={width < mdBreakpoint ? 18 : 24}
              copy="Meet Us"
              path="/team"
              color={white}
            />
          </View>
        </View>
      </View>
    </View>
  );
});
