import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { useDimensions } from 'react-native-web-hooks';

import { ExternalNavLink } from './ExternalNavLink';
import { HoverableNavLink } from './HoverableNavLink';
import { ProductsMenu } from './menus/ProductsMenu';
import { DeckMenu } from './menus/DeckMenu';
import { LearnMenu } from './menus/LearnMenu';
import { white } from '../../constants/brand';
import {
  smBreakpoint,
  mdBreakpoint,
  lgBreakpoint,
} from '../../constants/breakpoints';
import { Logo } from '../common/Logo';
import { MobileNavBar } from './MobileNavBar';

interface Props {}

export const NavBar: React.FC<Props> = React.memo(() => {
  const {
    window: { width },
  } = useDimensions();

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      backgroundColor: white,
      height:
        width < smBreakpoint
          ? 160
          : width < mdBreakpoint
          ? 200
          : width < lgBreakpoint
          ? 180
          : 200,
    },
  });

  if (width < mdBreakpoint) {
    return <MobileNavBar />;
  } else {
    return (
      <View style={styles.container}>
        <HoverableNavLink
          unfurlMenu={<ProductsMenu />}
          linkPath="/catalog"
          linkTitle="Products"
        />
        <HoverableNavLink
          unfurlMenu={<DeckMenu />}
          linkPath="/deck"
          linkTitle="Deck"
        />
        <View
          style={{
            width: '35%',
            alignSelf: 'center',
            alignItems: 'center',
          }}
        >
          <TouchableOpacity onPress={() => Linking.openURL('/')}>
            <Logo />
          </TouchableOpacity>
        </View>
        <HoverableNavLink
          unfurlMenu={<LearnMenu />}
          linkPath="/story"
          linkTitle="About"
        />
        <ExternalNavLink linkPath="https://happy-medium.co" linkTitle="Play" />
      </View>
    );
  }
});
