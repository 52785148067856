import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import { useFonts } from '@use-expo/font';

import { Layout } from '../components/common/Layout';
import {
  blue,
  white,
  PanamaBold,
  GTAmericaCompressedRegular,
  green,
  orange,
} from '../constants/brand';
import { useDimensions } from '../context/DimensionsContext';
import {
  smMdBreakpoint,
  mdLgBreakpoint,
  mdBreakpoint,
  smBreakpoint,
} from '../constants/breakpoints';
import { VideoPlayer } from '../components/common/Video';

interface Props {}

export const StoryScreen: React.FC<Props> = React.memo(() => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../assets/fonts/Panama-Bold.otf'),
    'GTAmerica-CompressedRegular': require('../../assets/fonts/GTAmerica-CompressedRegular.otf'),
  });

  const styles = StyleSheet.create({
    story: {
      fontFamily: GTAmericaCompressedRegular,
      fontSize: width < smMdBreakpoint ? 18 : width < mdLgBreakpoint ? 24 : 36,
      color: blue,
      letterSpacing: 1.3,
      marginVertical: 25,
    },
    callout: {
      fontFamily: PanamaBold,
      fontSize: width < smMdBreakpoint ? 14 : width < mdLgBreakpoint ? 18 : 20,
      color: orange,
      letterSpacing: 1.3,
    },
  });

  if (!fontsLoaded) return null;

  return (
    <Layout>
      <View
        style={{
          flex: 1,
          backgroundColor: blue,
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          paddingHorizontal: 20,
          paddingVertical: 100,
          zIndex: -1,
          maxWidth: '100vw',
        }}
      >
        <Image
          style={{
            width: 90,
            height: 40,
            resizeMode: 'contain',
            position: 'absolute',
            zIndex: 6,
            left: width < smBreakpoint ? '-2%' : '2%',
            top: '3.3%',
          }}
          source={require('../../assets/images/yellow-orange-tape.png')}
        />
        <Image
          style={{
            width: 90,
            height: 53,
            resizeMode: 'contain',
            position: 'absolute',
            zIndex: 6,
            right: width < smBreakpoint ? '-1.4%' : '2%',
            top: width < smBreakpoint ? '3.1%' : '3.1%',
          }}
          source={require('../../assets/images/blue-white-measure-tape.png')}
        />
        <View
          style={{
            flex: 1,
            backgroundColor: white,
            width: width < smBreakpoint ? '85vw' : '90vw',
            height: '100%',
            zIndex: 5,
            alignContent: width < mdBreakpoint ? 'center' : undefined,
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              alignSelf: width < mdBreakpoint ? 'center' : 'baseline',
              marginLeft: width < mdBreakpoint ? 0 : 140,
              alignItems: width < mdBreakpoint ? 'center' : undefined,
            }}
          >
            <View
              style={{
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  textTransform: 'uppercase',
                  color: blue,
                  fontFamily: PanamaBold,
                  fontSize: width < smBreakpoint ? 64 : 72,
                  marginTop: 50,
                }}
              >
                Our Story
              </Text>
              <Image
                style={{ height: 103, width: 207, resizeMode: 'contain' }}
                source={require('../../assets/images/drapes.png')}
              />
            </View>
          </View>

          <View
            style={{
              paddingHorizontal: width < smBreakpoint ? 20 : 60,
              marginVertical: 40,
            }}
          >
            <Text style={[styles.story, { marginBottom: 60 }]}>
              Happy Medium is the destination to find tools, content, and
              experiences for the casual adult artist, inspired by a future
              where the act of "creation" is increasingly seen as therapeutic,
              messy, and fun.
            </Text>

            <View
              style={{
                flexDirection: width < mdBreakpoint ? 'column-reverse' : 'row',
              }}
            >
              <View
                style={{
                  flexDirection: 'column',
                  width: width < mdBreakpoint ? '100%' : '60%',
                  paddingRight: width < mdBreakpoint ? 0 : 20,
                }}
              >
                <Text
                  style={[
                    styles.story,
                    { marginTop: width < smBreakpoint ? 40 : 0 },
                  ]}
                >
                  Tayler and Rett, the founders of Happy Medium, met in 2011 at
                  UNC Chapel Hill where they had back to back Art and Advanced
                  Macroeconomics classes.
                </Text>
                <Text style={[styles.story]}>
                  They started their first venture – a demand forecasting
                  consultancy - in school after their favorite biscuit shop was
                  perpetually sold out. Solve your own problems, right?
                </Text>
              </View>
              <View
                style={{
                  width: width < mdBreakpoint ? '100%' : '40%',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={[
                    styles.callout,
                    {
                      top: width < mdBreakpoint ? -40 : -60,
                      right:
                        width < mdBreakpoint
                          ? 40
                          : width < mdLgBreakpoint
                          ? 50
                          : 40,
                      position: 'absolute',
                      zIndex: 6,
                    },
                  ]}
                >
                  the founders
                </Text>
                <Image
                  style={{
                    height: width < mdBreakpoint ? 197 : 354,
                    width: width < mdBreakpoint ? 194 : 296,
                    resizeMode: 'contain',
                    position: 'absolute',
                    top: width < mdBreakpoint ? -30 : -60,
                    zIndex: 6,
                  }}
                  source={require('../../assets/images/orange-lasso.png')}
                />
                <View
                  style={{
                    backgroundColor: green,
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    height: width < mdBreakpoint ? 233 : 416,
                    width: width < mdBreakpoint ? 200 : 350,
                  }}
                >
                  <Image
                    style={{
                      height: '90%',
                      width: '90%',
                      resizeMode: 'contain',
                    }}
                    source={require('../../assets/images/graduation.png')}
                  />
                </View>
              </View>
            </View>

            <Text style={styles.story}>
              They moved to NYC in 2012, where Tayler worked in merchandising
              and eCommerce for big name brands. Rett worked in banking and
              taught himself how to code before becoming Head of Product and
              Technology at a startup. In 2017 they built a property management
              app that was acquired pre-launch.
            </Text>
            <Text style={styles.story}>
              Along the way, they became increasingly burnt out from staring at
              screens all day. They turned to arts and crafts to slow down,
              disconnect, and destress – something they hadn’t done since
              childhood.
            </Text>
            <Text style={styles.story}>
              Tayler, who was trained as a merchant under the customer-obsessed
              Mickey Drexler, was shocked at the state of the creative goods
              industry. Outdated websites, low quality products, and a complete
              lack of concern for customer success defined the likes of
              Michaels, Blick, and Hobby Lobby.
            </Text>
            <Text style={styles.story}>
              They saw this as a huge opportunity - the creative goods market is
              a $44B market with high margins and a high repeat purchase rate.
              Together, they could reinvent the way adults engage their
              creativity, transforming making art from a childhood pastime to a
              modern form of active meditation.
            </Text>

            <Text style={styles.story}>
              In the summer of 2019, they turned their living room into an art
              studio and started hosting art parties so they had an excuse to
              make art with as many people as possible. When they started
              getting invite requests from strangers, they knew they were on to
              something and Happy Medium was born.
            </Text>

            <VideoPlayer />
            <Text
              style={[
                styles.callout,
                { alignSelf: 'center', marginVertical: 5 },
              ]}
            >
              Art Party,
            </Text>
            <Text
              style={[
                styles.callout,
                { alignSelf: 'center', marginVertical: 5 },
              ]}
            >
              Feb 2020
            </Text>

            {/* // wrapper */}
          </View>
        </View>

        <Image
          style={{
            width: 100,
            height: 40,
            resizeMode: 'contain',
            position: 'absolute',
            zIndex: 6,
            left: '2%',
            bottom: width < smBreakpoint ? '3.5%' : '3.5%',
          }}
          source={require('../../assets/images/maroon-tape.png')}
        />
        <Image
          style={{
            width: 90,
            height: 53,
            resizeMode: 'contain',
            position: 'absolute',
            zIndex: 6,
            right: '2%',
            bottom: width < smBreakpoint ? '2.8%' : '3.2%',
          }}
          source={require('../../assets/images/blue-green-tape.png')}
        />
      </View>
    </Layout>
  );
});
