import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Linking,
  ImageURISource,
} from 'react-native';
import { useFonts } from '@use-expo/font';

import { Layout } from '../components/common/Layout';
import { wheat, GTAmericaCompressedRegular, blue } from '../constants/brand';
import { useDimensions } from '../context/DimensionsContext';
import { smMdBreakpoint, mdLgBreakpoint } from '../constants/breakpoints';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { CreativityFooter } from '../components/creativity/Footer';
import { Hoverable } from 'react-native-web-hooks';
import { CreativityLink } from '../components/creativity/CreativityLink';

interface Props {}

export const CreativityScreen: React.FC<Props> = React.memo(() => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'GTAmerica-CompressedRegular': require('../../assets/fonts/GTAmerica-CompressedRegular.otf'),
  });

  const styles = StyleSheet.create({
    quoteContainer: {
      marginTop: width < smMdBreakpoint ? 100 : 180,
      alignItems: 'center',
    },
    innerContainer: { alignItems: 'center' },

    quote: {
      fontSize: width < smMdBreakpoint ? 36 : 72,
      color: blue,
      fontFamily: GTAmericaCompressedRegular,
      letterSpacing: 1.3,
      textAlign: 'center',
      paddingHorizontal: width < smMdBreakpoint ? 20 : 80,
    },
    logo: {
      width: width < smMdBreakpoint ? 200 : 300,
      height: 100,
      resizeMode: 'contain',
      marginTop: width < smMdBreakpoint ? 10 : 25,
    },
  });

  if (!fontsLoaded) return null;

  return (
    <Layout>
      <View
        style={{
          zIndex: -1,
          backgroundColor: wheat,
          flex: 1,
          height: '100%',
          width: '100%',
          // justifyContent: 'center',
          alignItems: 'center',
          // alignContent: 'center',
          paddingHorizontal: 20,
          paddingVertical: 100,
          maxWidth: '100vw',
        }}
      >
        <Text
          style={{
            fontFamily: GTAmericaCompressedRegular,
            letterSpacing: 1.3,
            fontSize:
              width < smMdBreakpoint ? 22 : width < mdLgBreakpoint ? 30 : 36,
            color: blue,
            textAlign: 'center',
            paddingHorizontal: 20,
          }}
        >
          Creativity is an essential part of a healthy and balanced life - but
          don’t take our word for it.
        </Text>
        <CreativityLink
          url={
            'https://www.npr.org/2019/12/30/792439555/making-art-is-good-for-your-health-heres-how-to-start-a-habit'
          }
          quote={
            'Making Art Is Good For Your Health. Here’s How To Start A Habit'
          }
          image={require('../../assets/images/npr-logo.jpg')}
        />
        <CreativityLink
          url={
            'https://www.nytimes.com/2018/05/10/smarter-living/the-case-for-hobbies-ideas.html'
          }
          quote={'The Case for Having a Hobby'}
          image={require('../../assets/images/nyt-logo.png')}
        />

        <CreativityLink
          url={
            'https://www.health.harvard.edu/mental-health/the-healing-power-of-art'
          }
          quote={'The Healing Power of Art'}
          image={require('../../assets/images/harvard-logo.png')}
        />

        <CreativityLink
          url={
            'https://www.npr.org/sections/health-shots/2020/01/11/795010044/feeling-artsy-heres-how-making-art-helps-your-brain'
          }
          quote={'Feeling Artsy? Here’s How Making Art Helps Your Brain'}
          image={require('../../assets/images/npr-logo.jpg')}
        />

        <CreativityLink
          url={
            'https://www.huffpost.com/entry/study-says-making-art-reduces-stress_n_576183ece4b09c926cfdccac'
          }
          quote={
            'Study Says Making Art Reduces Stress, Even If You Kind Of Suck At It'
          }
          image={require('../../assets/images/huffpo-logo.png')}
        />

        <CreativityLink
          url={'https://www.apa.org/monitor/2019/05/probing-art'}
          quote={'Probing the Power and Imprtance of Art'}
          image={require('../../assets/images/apa-logo.png')}
        />
      </View>
      <CreativityFooter />
    </Layout>
  );
});
