import { findDOMNode } from 'react-dom';

import { Platform } from '../libs/platform';

export function findNode(ref: any) {
  try {
    let node = ref && (ref.current || ref);

    if (node && (node as any).getNode && (node as any).getNode())
      node = (node as any).getNode();

    if (node && (node as any)._touchableNode)
      node = (node as any)._touchableNode;

    if (node && (node as any)._node) node = (node as any)._node;

    if (node && Platform.OS === 'web') node = findDOMNode(node);

    return node;
  } catch (error) {
    console.error('Failed to find node', error, { ref });
    return null;
  }
}

export interface StringIndex<T = string> {
  [key: string]: T;
}

/**
 * Filters an array of objects by custom predicates.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export type Filter<T> = StringIndex<(v: T) => boolean | undefined | string>;

export function filterArray<T>(array: T[], filters: Filter<T>): T[] {
  const filterKeys = Object.keys(filters);

  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true;

      return filters[key](item);
    });
  });
}
/**
 *
 * @param {Array} array: the array to check
 * @param {any} arrayValue: value to toggle
 * @returns {Array}
 */

export function toggleArrayValue(array: any[], arrayValue: any) {
  return array.includes(arrayValue)
    ? array.filter(el => el !== arrayValue)
    : [...array, arrayValue];
}
