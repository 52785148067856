import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';

import { Layout } from '../common/Layout';
import { rust, white, PanamaBold, PanamaRegular } from '../../constants/brand';
import { useFonts } from 'expo-font';
import { useDimensions } from '../../context/DimensionsContext';
import { smMdBreakpoint, mdBreakpoint } from '../../constants/breakpoints';

interface Props {}

export const Money: React.FC<Props> = () => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../../assets/fonts/Panama-Bold.otf'),
    'Panama-Regular': require('../../../assets/fonts/Panama-Regular.otf'),
  });
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignContent: 'center',
      width: '100%',
      // height: width < mdBreakpoint ? 700 : '100%',
      minHeight: 550,
      backgroundColor: rust,
      paddingBottom: width < mdBreakpoint ? 0 : 40,
    },
    text: {
      color: white,
      letterSpacing: 1.3,
    },
    largeText: {
      textTransform: 'uppercase',
      fontSize: width < mdBreakpoint ? 36 : 72,
      fontFamily: PanamaBold,
    },
    secondaryText: {
      fontFamily: PanamaRegular,
      fontSize: width < mdBreakpoint ? 18 : 48,
    },
  });
  if (!fontsLoaded) return null;
  return (
    <View style={styles.container}>
      <Image
        style={{
          position: 'absolute',
          right: width < mdBreakpoint ? undefined : 30,
          left: width >= mdBreakpoint ? undefined : 30,
          top: width < mdBreakpoint ? 120 : undefined,
          height: width < mdBreakpoint ? '40%' : '70%',
          width: 40,
          resizeMode: 'contain',
        }}
        source={require('../../../assets/images/blue-white-measure-tape-long.png')}
      />
      <Text
        style={{
          color: white,
          fontFamily: PanamaBold,
          fontSize: width < mdBreakpoint ? 18 : 24,
          marginTop: width < mdBreakpoint ? 60 : 120,
          marginLeft: width < mdBreakpoint ? 60 : 80,
          marginRight: width < mdBreakpoint ? 60 : undefined,
          textAlign: width < mdBreakpoint ? 'center' : 'left',
          fontStyle: 'italic',
        }}
      >
        When adding more color to your life is an option, the answer is always
        yes.
      </Text>
      <View style={{ height: width < mdBreakpoint ? 50 : 100 }} />
      <Text
        style={[
          styles.text,
          styles.largeText,
          {
            textAlign: 'center',
          },
        ]}
      >
        $44 Billion
      </Text>
      <Text
        style={[
          styles.text,
          styles.secondaryText,
          {
            textTransform: 'uppercase',
            textAlign: 'center',
          },
        ]}
      >
        US Market Size
      </Text>
      <View
        style={{
          flexDirection: width < mdBreakpoint ? 'column-reverse' : 'row',
          justifyContent:
            width < mdBreakpoint ? 'space-evenly' : 'space-around',
          alignItems: 'center',
          marginVertical: width < mdBreakpoint ? 10 : 80,
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            // height: 200,
            // width: 300,
            marginTop: width < mdBreakpoint ? 35 : 40,
          }}
        >
          <Text style={[styles.text, styles.largeText]}>70%</Text>
          <Text style={[styles.text, styles.secondaryText]}>Product</Text>
          <Text style={[styles.text, styles.secondaryText]}>Margin</Text>
        </View>
        <Image
          style={{
            height: width < mdBreakpoint ? 150 : 250,
            width: width < mdBreakpoint ? 95 : 159,
            bottom: width < mdBreakpoint ? -60 : 0,
            resizeMode: 'contain',
            position: 'absolute',
            left: width < mdBreakpoint ? undefined : 0,
            right: width < mdBreakpoint ? 30 : 0,
            marginHorizontal: width < mdBreakpoint ? undefined : 'auto',
          }}
          source={require('../../../assets/images/collage-flowers.png')}
        />
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            // height: 200,
            // width: 300,
            marginTop: width < mdBreakpoint ? 35 : 40,
          }}
        >
          <Text style={[styles.text, styles.largeText]}>2.5x</Text>
          <Text style={[styles.text, styles.secondaryText]}>Purchases/</Text>
          <Text style={[styles.text, styles.secondaryText]}>year</Text>
        </View>
      </View>
    </View>
  );
};
