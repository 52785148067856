export const deckSlides = [
  {
    uri: 'https://pitchdeckme.s3.amazonaws.com/HM_Deck_Cover.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.002.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.003.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.004.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.005.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.006.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.007.jpeg',
  },
  // {
  //   uri:
  //     'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.008.jpeg',
  // },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.009.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/HM_Deck_Primary_Customer_Segments.001.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.011.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.012.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.013.jpeg',
  },
  {
    uri: 'https://pitchdeckme.s3.amazonaws.com/HM_Deck_Product_Roadmap.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/HM_Deck_Drawing_Unit_Economics.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.017.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.018.jpeg',
  },
  {
    uri: 'https://pitchdeckme.s3.amazonaws.com/HM_Deck_MM_Advisor.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.019.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.021.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.020.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.022.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.023.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.024.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.025.jpeg',
  },
  {
    uri: 'https://pitchdeckme.s3.amazonaws.com/HM_Deck_Progress.jpeg',
  },
  {
    uri: 'https://pitchdeckme.s3.amazonaws.com/HM_Deck_Timeline.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.028.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.029.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.030.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.031.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.032.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.033.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.034.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.035.jpeg',
  },
  {
    uri:
      'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/Deck/Full+Deck.036.jpeg',
  },
];
