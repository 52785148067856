import 'react-native-gesture-handler';
import * as React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import { client } from './src/libs/apollo';
import { Routes } from './src/navigation/Routes';
import { DimensionsProvider } from './src/context/DimensionsContext';
import { AppLayoutProvider } from './src/context/LayoutContext';
import { AuthProvider } from './src/context/AuthContext';

export default function App() {
  return (
    <DimensionsProvider>
      <AppLayoutProvider>
        <ApolloProvider client={client}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </ApolloProvider>
      </AppLayoutProvider>
    </DimensionsProvider>
  );
}
