import React from 'react';

import { Layout } from '../components/common/Layout';
import { HomeFooter } from '../components/home/Footer';
import { HomeHero } from '../components/home/Hero';
import { HomeDreamTeam } from '../components/home/DreamTeam';
import { HomeProductTeaser } from '../components/home/ProductTeaser';
import { Money } from '../components/home/Money';

interface Props {}

export const HomeScreen: React.FC<Props> = React.memo(() => {
  return (
    <Layout>
      <HomeHero />
      <HomeDreamTeam />
      <Money />
      <HomeProductTeaser />
      <HomeFooter />
    </Layout>
  );
});
