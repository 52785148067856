import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
// import { useFonts } from 'expo-font';
import { useFonts } from 'expo-font/build/FontHooks';
import { AppLoading } from 'expo';

import { green, PanamaBold, white } from '../constants/brand';
import { useAuthContext, useAuthDispatch } from '../context/AuthContext';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { LoginForm } from '../forms/LoginForm';
import { useDimensions } from '../context/DimensionsContext';
// import { useFonts } from '@use-expo/font';

interface Props {}

export const LoginScreen: React.FC<Props> = React.memo(() => {
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const { width } = useDimensions();
  const logo = require('@hmip/client/assets/images/logo-white@200x.png');

  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../assets/fonts/Panama-Bold.otf'),
    'GTAmerica-Regular': require('../../assets/fonts/GTAmerica-Regular.otf'),
  });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: green,
      maxWidth: '100%',
      overflow: 'hidden',
    },
    header: {
      fontFamily: PanamaBold,
      textTransform: 'uppercase',
      fontSize: width < mdBreakpoint ? 24 : 36,
      color: white,
    },
    logo: {
      height: width < mdBreakpoint ? 90 : 139,
      width: width < mdBreakpoint ? 320 : 584,
      resizeMode: 'contain',
      marginBottom: 25,
    },
  });
  const dispatch = useAuthDispatch();

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <View style={styles.container}>
        <Image accessibilityLabel="logo" style={styles.logo} source={logo} />
        <Text style={styles.header}>Creativity, Positivty,</Text>
        <Text
          style={[
            styles.header,
            { marginBottom: width < mdBreakpoint ? 30 : 60 },
          ]}
        >
          a few Art Supplies.
        </Text>
        <LoginForm />
      </View>
    );
  }
});
