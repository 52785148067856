import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import { useFonts } from '@use-expo/font';

import { white, PanamaRegular, blue, wheat } from '../../constants/brand';
import { useDimensions } from '../../context/DimensionsContext';
import { Button } from '../common/Button';
import {
  mdBreakpoint,
  lgBreakpoint,
  smBreakpoint,
  mdLgBreakpoint,
  smMdBreakpoint,
} from '../../constants/breakpoints';

interface Props {}

export const HomeHero: React.FC<Props> = React.memo(() => {
  const { height } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Regular': require('../../../assets/fonts/Panama-Regular.otf'),
  });
  const { width } = useDimensions();
  const styles = StyleSheet.create({
    copy: {
      fontFamily: PanamaRegular,
      textTransform: 'uppercase',
      fontSize:
        width < smBreakpoint
          ? 24
          : width < mdBreakpoint
          ? 30
          : width < lgBreakpoint
          ? 36
          : 48,
      letterSpacing: 1.3,
      color: blue,
    },
  });
  if (!fontsLoaded) return null;
  return (
    <View style={{ flex: 1, width: '100%', height: '100%', zIndex: -1 }}>
      <Image
        style={{
          width,
          height: width < smMdBreakpoint ? 550 : '100vh',
          resizeMode: width < mdBreakpoint ? 'cover' : 'repeat',
        }}
        // source={require('../../../assets/images/charcoal_swipe.png')}
        source={
          width < mdBreakpoint
            ? require('../../../assets/images/pigments2.JPG')
            : require('../../../assets/images/pigment-wall-update.png')
        }
      />
      <View
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            opacity: 0.9,
            backgroundColor: width < mdBreakpoint ? wheat : white,
            height: width < smBreakpoint ? 250 : '40%',
            width:
              width < smBreakpoint
                ? '90%'
                : width < smMdBreakpoint
                ? '80%'
                : width < mdLgBreakpoint
                ? '60%'
                : width < lgBreakpoint
                ? '50%'
                : '42%',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <Text style={styles.copy}>CREATIVITY, POSITIVITY,</Text>
          <Text style={[styles.copy]}>A FEW ART SUPPLIES</Text>
          <Text
            style={{
              fontFamily: PanamaRegular,
              fontSize: 18,
              letterSpacing: 1.3,
              color: blue,
              marginTop: 20,
              marginBottom: 30,
            }}
          >
            (and lots of $$$)
          </Text>
          <Button
            copy="Our Story"
            path="/story"
            fontSize={width < mdBreakpoint ? 18 : 24}
          />
        </View>
      </View>
    </View>
  );
});
