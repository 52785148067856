import React from 'react';
import { Image } from 'react-native';

interface Props {
  height?: number;
  width?: number;
}

export const Logo: React.FC<Props> = React.memo(({ height, width }) => {
  const defaultHeight = 93;
  const defaultWidth = 390;
  return (
    <Image
      source={require('../../../assets/images/logo-green@200x.png')}
      style={{
        height: height ? height : defaultHeight,
        width: width ? width : defaultWidth,
        resizeMode: 'contain',
      }}
    />
  );
});
