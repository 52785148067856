import React from 'react';
import { Layout } from '../components/common/Layout';
import { View, StyleSheet, Text, Image } from 'react-native';
import {
  green,
  PanamaBold,
  white,
  GTAmericaCompressedRegular,
} from '../constants/brand';
import { useFonts } from '@use-expo/font';
import { useDimensions } from '../context/DimensionsContext';
import { smMdBreakpoint, mdLgBreakpoint } from '../constants/breakpoints';
import { TextFooter } from '../components/common/TextFooter';

interface Props {}

export const ProductPhilosophyScreen: React.FC<Props> = React.memo(() => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../assets/fonts/Panama-Bold.otf'),
  });
  const styles = StyleSheet.create({
    header: {
      fontFamily: PanamaBold,
      fontSize: width < smMdBreakpoint ? 36 : 48,
      textAlign: 'center',
      letterSpacing: 1.3,
      textTransform: 'uppercase',
      color: white,
    },
    copy: {
      fontFamily: GTAmericaCompressedRegular,
      fontSize: width < smMdBreakpoint ? 18 : 24,
      textAlign: 'center',
      letterSpacing: 1.3,
      color: white,
      maxWidth:
        width < smMdBreakpoint ? '90%' : width < mdLgBreakpoint ? '70%' : '50%',
      marginVertical: 20,
    },
    squiggle: {
      height: width < smMdBreakpoint ? 20 : 40,
      width: width < smMdBreakpoint ? 75 : 150,
      marginVertical: 80,
    },
  });
  if (!fontsLoaded) return null;
  return (
    <Layout>
      <View
        style={{
          flex: 1,
          backgroundColor: green,
          alignItems: 'center',
          zIndex: -1,
          height: '100%',
          width: '100%',
          paddingHorizontal: width < smMdBreakpoint ? 20 : 50,
          paddingVertical: 100,
          maxWidth: '100vw',
        }}
      >
        <Text
          style={{
            fontFamily: GTAmericaCompressedRegular,
            fontSize: width < smMdBreakpoint ? 24 : 32,
            textAlign: 'center',
            letterSpacing: 1.3,
            color: white,
          }}
        >
          Our product philosophy is inspired by hundreds of customers interviews
          and dozens of prototypes.
        </Text>
        <Image
          style={{
            width: width < smMdBreakpoint ? 250 : 350,
            height: width < smMdBreakpoint ? 29 : 40,
            resizeMode: 'contain',
            marginTop: 50,
            alignSelf: width < smMdBreakpoint ? 'flex-end' : 'center',
            marginBottom: 100,
          }}
          source={require('../../assets/images/blue-orange-tape-long.png')}
        />
        <Text style={styles.header}>High-Quality</Text>
        <Text style={styles.copy}>
          Our customer want to make beautiful artwork. The quality of the
          supplies directly affects the quality of the final artwork. Most
          supplies on the market are cheaply made and packed with fillers.
        </Text>
        <Text style={styles.copy}>
          We prioritize quality in everything we do.
        </Text>
        <Image
          style={styles.squiggle}
          source={require('../../assets/images/squiggles-blue.png')}
        />
        <Text style={styles.header}>Sustainable</Text>
        <Text style={styles.copy}>
          We’re facing an environmental crisis caused in part by mindless over
          consumption. Every product we make is intentional, reusable and
          designed to last. We avoid plastic and toxic ingredients at all costs.
        </Text>
        <Text style={styles.copy}>Good for you and good for the world.</Text>
        <Image
          style={styles.squiggle}
          source={require('../../assets/images/squiggles-blue.png')}
        />
        <Text style={styles.header}>Aesthetic</Text>
        <Text style={styles.copy}>
          Most art supplies today ends up in a craft box or in an art closet -
          it’s ugly and hard to store. We’re designing beautiful products meant
          to be left out and used often.
        </Text>
        <Text style={styles.copy}>
          Art supplies as beautiful as art themselves.
        </Text>
      </View>
      <TextFooter text={'Art curious, not art ambitious.'} />
    </Layout>
  );
});
