// product catalog
export const catP1 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+1.jpg';
export const catP2 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+2.jpg';
export const catP3 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+3.jpg';
export const catP4 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+4.jpg';
export const catP5 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+5.jpg';
export const catP6 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+6.jpg';
export const catP7 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+7.jpg';
export const catP8 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+8.jpg';
export const catP9 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+9.jpg';
export const catP10 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+10.jpg';
export const catP11 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+11.jpg';
export const catP12 =
  'https://hmproductcatalog.s3.amazonaws.com/8.12+product+catalog/8.12+/Page+12.jpg';
