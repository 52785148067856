/* eslint-env browser */

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    // navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //   for (let registration of registrations) {
    //     registration.unregister().then(function (bool) {
    //       console.log('unregistered sw');
    //     });
    //   }
    // });
    navigator.serviceWorker
      .register('/expo-service-worker.js', {
        scope: '/',
      })
      .then(function (info) {
        info.unregister().then(function (bool) {
          console.log('unregistered?', bool);
        });
        console.info('Registered service-worker', info);
      })
      .catch(function (error) {
        console.info('Failed to register service-worker', error);
      });
  });
}
