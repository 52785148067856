import React, { useState, useEffect } from 'react';
import {
  Text,
  TouchableOpacity,
  Linking,
  StyleSheet,
  View,
} from 'react-native';
import * as Font from 'expo-font';
import { Hoverable } from 'react-native-web-hooks';

import { green } from '../../constants/brand';

interface Props {
  linkTitle: string;
  linkPath: string;
}

export const NavLink: React.FC<Props> = React.memo(
  ({ linkTitle, linkPath }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      (async () => {
        try {
          await Font.loadAsync({
            'GTAmerica-CompressedRegular': require('../../../assets/fonts/GTAmerica-CompressedRegular.otf'),
            'GTAmerica-CompressedMedium': require('../../../assets/fonts/GTAmerica-CompressedMedium.otf'),
          });
        } catch ({ message }) {
          console.log(`Error loading font: ${message}`);
        } finally {
          setLoaded(true);
        }
      })();
    }, []);

    const styles = StyleSheet.create({
      container: {
        height: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
      },
      link: {
        color: green,
        fontSize: 36,
        letterSpacing: 1.3,
      },
    });

    if (!loaded) return null;

    return (
      <Hoverable>
        {(isHovered) => (
          <View style={styles.container}>
            <TouchableOpacity
              accessible
              onPress={() => {
                Linking.openURL(`${linkPath}`);
              }}
            >
              <Text
                style={[
                  styles.link,
                  {
                    fontFamily: isHovered
                      ? 'GTAmerica-CompressedMedium'
                      : 'GTAmerica-CompressedRegular',
                    textDecorationLine: isHovered ? 'underline' : 'none',
                  },
                ]}
              >
                {linkTitle}
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </Hoverable>
    );
  }
);
