import React from 'react';
import { View, Text, Image, Linking } from 'react-native';
import { useFonts } from '@use-expo/font';

import {
  orange,
  PanamaBold,
  GTAmericaCompressedRegular,
  white,
} from '../constants/brand';
import { Layout } from '../components/common/Layout';
import { useDimensions } from '../context/DimensionsContext';
import { smMdBreakpoint, mdLgBreakpoint } from '../constants/breakpoints';
import { TouchableOpacity } from '../components/common/TouchableOpacity';

interface Props {}

export const ContactScreen: React.FC<Props> = React.memo(() => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../assets/fonts/Panama-Bold.otf'),
    'GTAmerica-CompressedRegular': require('../../assets/fonts/GTAmerica-CompressedRegular.woff2'),
  });
  if (!fontsLoaded) return null;
  return (
    <Layout>
      <View
        style={{
          zIndex: -1,
          alignSelf: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <View
          style={{
            backgroundColor: orange,
            flex: 1,
            width: '100%',
            height: '100%',
            paddingHorizontal: 30,
            alignItems: 'center',
            paddingVertical: 100,
            zIndex: -1,
          }}
        >
          <View style={{ width: width * 0.8 }}>
            <Text
              style={{
                fontFamily: PanamaBold,
                fontSize:
                  width < smMdBreakpoint
                    ? 42
                    : width < mdLgBreakpoint
                    ? 56
                    : 72,
                textTransform: 'uppercase',
                color: white,
                alignSelf: 'flex-start',
              }}
            >
              LET’S MAKE ART TOGETHER
            </Text>
            <TouchableOpacity
              onPress={() => Linking.openURL('mailto:founders@happy-medium.co')}
            >
              <Text
                style={{
                  fontFamily: GTAmericaCompressedRegular,
                  fontSize:
                    width < smMdBreakpoint
                      ? 24
                      : width < mdLgBreakpoint
                      ? 28
                      : 36,
                  color: white,
                  alignSelf: 'flex-start',
                  marginBottom: 60,
                  textDecorationLine: 'underline',
                }}
              >
                founders@happy-medium.co
              </Text>
            </TouchableOpacity>
          </View>
          <Image
            style={{
              resizeMode: 'cover',
              width: width * 0.8,
              height:
                width < smMdBreakpoint
                  ? 200
                  : width < mdLgBreakpoint
                  ? 350
                  : 500,
            }}
            source={require('../../assets/images/contact-swipe.png')}
          />
        </View>
      </View>
    </Layout>
  );
});
