import React, { useRef, useCallback, useState } from 'react';
import {
  View,
  StyleSheet,
  Image,
  ImageURISource,
  FlatListProps,
  FlatList,
  TouchableOpacity,
  Text,
  ViewToken,
  ViewabilityConfig,
} from 'react-native';
import { useFonts } from 'expo-font';

import { useDimensions } from '../context/DimensionsContext';
import {
  white,
  blue,
  green,
  GTAmericaRegular,
  GTAmericaMedium,
} from '../constants/brand';
import { useAuthState } from '../context/AuthContext';
import { smBreakpoint, mdLgBreakpoint } from '../constants/breakpoints';
import { deckSlides } from '../data/slides';
import { LoginScreen } from './LoginScreen';
import { NavBar } from '../components/nav/NavBar';

interface Props {}

const _viewabilityConfig: ViewabilityConfig = {
  itemVisiblePercentThreshold: 60,
};

export const DeckScreen: React.FC<Props> = React.memo(() => {
  const [fontsLoaded] = useFonts({
    'GTAmerica-Regular': require('../../assets/fonts/GTAmerica-Regular.otf'),
    'GTAmerica-Medium': require('../../assets/fonts/GTAmerica-Medium.otf'),
  });
  const ratio = 1920 / 1080;
  const [_visibleItems, _setVisibleItems] = useState<any>(null);
  const listRef = useRef<FlatList>(null);
  const authState = useAuthState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: white,
      zIndex: -1,
    },
    page: {
      flex: 1,
      height: width < smBreakpoint ? 200 : height - 200,
      width: (width < smBreakpoint ? 200 : height - 200) * ratio,
      resizeMode: 'contain',
    },
  });
  const x = process.env.REACT_APP_AUTH_TRUE;

  const renderItem: FlatListProps<{ uri: string }>['renderItem'] = ({
    item: uri,
  }) => {
    return <Image style={styles.page} source={uri as ImageURISource} />;
  };

  const _scrollToIndex = (index: number) => {
    if (listRef.current) {
      listRef.current.scrollToIndex({
        animated: false,
        viewPosition: 0,
        index: index,
      });
    } else {
      console.log('no ref');
    }
  };

  const _onViewableItemsChanged = useRef(
    ({
      viewableItems,
      changed,
    }: {
      viewableItems: ViewToken[];
      changed: ViewToken[];
    }) => {
      _setVisibleItems(viewableItems.map((i) => i.index));
    }
  );

  const _getItemLayout = (data: any, index: number) => {
    const length = width < smBreakpoint ? 200 + 10 : height - 200 + 10;
    return { length, offset: length * index, index };
  };

  const ITEM_HEIGHT = width < smBreakpoint ? 200 + 10 : height - 200 + 10;

  const _onScrollToIndexFailed: FlatListProps<string>['onScrollToIndexFailed'] = (info: {
    index: number;
    highestMeasuredFrameIndex: number;
    averageItemLength: number;
  }) => {
    console.error(info);
  };
  const onScrollToIndexFailed = useCallback(_onScrollToIndexFailed, []);

  const sideNavItems = [
    { title: '1. Cover', index: 0 },
    { title: '2. Mission', index: 1 },
    { title: '3. Problem', index: 3 },
    { title: '4. Solution', index: 4 },
    { title: '5. Why Now', index: 5 },
    { title: '6. Market Size', index: 6 },
    // { title: '7. Market Breakdown', index: 7 },
    { title: '7. Competitive Landscape', index: 7 },
    { title: '8. Early Adopters', index: 8 },
    { title: '9. Secondary Segements', index: 9 },
    { title: '10. Online Communities', index: 10 },
    { title: '11. Customer Acquisition', index: 11 },
    { title: '12. Product Roadmap', index: 12 },
    { title: '13. Drawing Unit Economics', index: 13 },
    { title: '14. Team', index: 14 },
    { title: '15. Advisors', index: 15 },
    { title: '16. Investors', index: 16 },
    { title: '17. Branding', index: 17 },
    { title: '18. Industrial Design', index: 18 },
    { title: '19. OOH', index: 19 },
    { title: '20. Go to Market', index: 20 },
    { title: '21. Sales Channels', index: 21 },
    { title: '22. Our Moat', index: 22 },
    { title: '23. Validation', index: 23 },
    { title: '24. Progress', index: 24 },
    { title: '25. Appendix', index: 26 },
  ];

  const _itemSeparator = () => <View style={{ height: 10 }} />;

  if ((authState as any) === x) {
    return (
      <View
        style={{
          flex: 1,
          maxHeight: height,
          overflow: 'hidden',
        }}
      >
        <View style={{ maxWidth: '100vw' }}>
          <NavBar />
        </View>
        <View
          style={{
            zIndex: -1,
            backgroundColor: white,
            maxHeight: ITEM_HEIGHT + 10,
            flexDirection: width < mdLgBreakpoint ? 'column' : 'row',
            minHeight: '100vh',
            width: '100vw',
          }}
        >
          {fontsLoaded && (
            <View
              style={{
                display: width < mdLgBreakpoint ? 'none' : 'flex',
                height: ITEM_HEIGHT,
                width: 220,
                backgroundColor: white,
                zIndex: 10,
              }}
            >
              {sideNavItems.map((value) => {
                return (
                  <TouchableOpacity
                    key={`${value.index}+${value.title}`}
                    onPress={() => {
                      _scrollToIndex(value.index);
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: _visibleItems?.includes(value.index)
                          ? GTAmericaMedium
                          : GTAmericaRegular,
                        color: _visibleItems?.includes(value.index)
                          ? blue
                          : green,
                        textDecorationLine: _visibleItems?.includes(value.index)
                          ? 'underline'
                          : 'none',
                        paddingLeft: 5,
                      }}
                    >
                      {value.title}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          <View
            style={{
              height: height,
              width: width < mdLgBreakpoint ? '100%' : width - 220,
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <FlatList
              ref={listRef}
              onScrollToIndexFailed={onScrollToIndexFailed}
              getItemLayout={_getItemLayout}
              ItemSeparatorComponent={_itemSeparator}
              onViewableItemsChanged={_onViewableItemsChanged.current}
              data={deckSlides}
              renderItem={renderItem}
              viewabilityConfig={_viewabilityConfig}
              keyExtractor={(item: any, index: any) => `${item}-${index}`}
              contentContainerStyle={{
                paddingBottom: 200,
                width: width < mdLgBreakpoint ? '100%' : width - 220,
                alignItems: 'center',
              }}
            />
          </View>
        </View>
      </View>
    );
  } else {
    return <LoginScreen />;
  }
});
