import React from 'react';
import { Text, Linking, StyleSheet } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { useFonts } from '@use-expo/font';

import { TouchableOpacity } from '../../common/TouchableOpacity';
import { green } from '../../../constants/brand';

interface Props {
  title: string;
  path: string;
}

export const MenuItem: React.FC<Props> = React.memo(({ title, path }) => {
  const [fontsLoaded] = useFonts({
    'GTAmerica-CompressedRegular': require('../../../../assets/fonts/GTAmerica-CompressedRegular.otf'),
  });

  const styles = StyleSheet.create({
    navItem: {
      fontFamily: 'GTAmerica-CompressedRegular',
      fontSize: 24,
      letterSpacing: 1.3,
      color: green,
      marginVertical: 5,
    },
    hover: {
      textDecorationLine: 'underline',
      textDecorationColor: green,
    },
  });

  if (!fontsLoaded) return null;

  return (
    <Hoverable>
      {(isHovered) => (
        <TouchableOpacity onPress={() => Linking.openURL(`${path}`)}>
          <Text style={[styles.navItem, isHovered && styles.hover]}>
            {title}
          </Text>
        </TouchableOpacity>
      )}
    </Hoverable>
  );
});
