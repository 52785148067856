import React from 'react';
import {
  FlatList,
  FlatListProps,
  ImageURISource,
  Image,
  StyleSheet,
  View,
} from 'react-native';

import { Layout } from '../components/common/Layout';
import { useDimensions } from '../context/DimensionsContext';
import { white } from '../constants/brand';
import { smBreakpoint } from '../constants/breakpoints';

interface Props {}

const teamSlides = [
  {
    uri: 'https://pitchdeckme.s3.amazonaws.com/HM_Deck_Product_Roadmap.jpeg',
  },
];

export const RoadmapScreen: React.FC<Props> = React.memo(() => {
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: white,
      zIndex: -1,
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      maxWidth: '100vw',
      flex: 1,
    },
    page: {
      flex: 1,
      height: width < smBreakpoint ? 200 : height - 200,
      resizeMode: 'contain',
      marginBottom: 10,
      zIndex: -1,
      width,
    },
  });
  const renderItem: FlatListProps<{ uri: string }>['renderItem'] = ({
    item: uri,
  }) => {
    return <Image style={styles.page} source={uri as ImageURISource} />;
  };
  return (
    <Layout>
      <View style={styles.container}>
        <FlatList
          data={teamSlides}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${item}-${index}`}
        />
      </View>
    </Layout>
  );
});
