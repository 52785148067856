import { useState, useEffect } from 'react';

interface Test {}

export function usePersistedState(
  key: string,
  defaultValue: string
): [any, React.Dispatch<any>] {
  const [state, setState] = useState(() => {
    const persistedState = localStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : defaultValue;
  });
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
