export const orange = '#F15C2B';
export const green = '#008965';
export const rust = '#833D39';
export const blue = '#243D87';
export const sand = '#E8CFBE';
export const chartreuse = '#D3C129';
export const white = '#FFFCF9';
export const wheat = '#F1E5D8';

export const PanamaBold = 'Panama-Bold';
export const PanamaRegular = 'Panama-Regular';
export const TrailersDemiBold = 'TTTrailers-DemiBold';
export const GTAmericaCompressed = 'GTAmerica-Compressed';
export const GTAmericaCompressedRegular = 'GTAmerica-CompressedRegular';
export const GTAmericaCompressedMedium = 'GTAmerica-CompressedMedium';
export const GTAmericaRegular = 'GTAmerica-Regular';
export const GTAmericaLight = 'GTAmerica-Light';
export const GTAmericaMedium = 'GTAmerica-Medium';
