import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  View,
  TextInput,
  StyleSheet,
  ActivityIndicator,
  Image,
  Linking,
  Text,
} from 'react-native';
// import { useFonts } from 'expo-font';
import { useFonts } from 'expo-font/build/FontHooks';

import {
  PanamaBold,
  white,
  chartreuse,
  GTAmericaRegular,
} from '../constants/brand';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { useAuthDispatch } from '../context/AuthContext';

interface Props {}

interface FormValues {
  password: string;
}

export const LoginForm: React.FC<Props> = () => {
  const submitArrow = require(`@hmip/client/assets/images/arrow-right-red@300x.png`);
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../assets/fonts/Panama-Bold.otf'),
    'GTAmerica-Regular': require('../../assets/fonts/GTAmerica-Regular.otf'),
  });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const styles = StyleSheet.create({
    container: {
      height: 80,
      width: '100%',
      alignItems: 'center',
    },
    subscribeForm: {
      marginTop: 20,
      marginBottom: 20,
      marginHorizontal: 0,
      paddingHorizontal: 15,
      marginLeft: 30,
      width: '100%',
      // flexGrow: 1,
      maxWidth: 500,
      flexDirection: 'row',
      // flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    emailInput: {
      minWidth: '50%',
      maxWidth: 400,
      fontFamily: PanamaBold,
      height: 29,
      fontSize: 18,
      letterSpacing: 0.9,
      paddingLeft: 2,
      color: white,
      // placeholderTextColor: white, // TODOD: throwing an error in Chrome only?
      flexGrow: 1,
      borderBottomWidth: 1,
      borderBottomColor: white,
      outline: 'none',
      outlineColor: 'transparent',
      outlineWidth: 0,
    },
    submitArrow: {
      width: 30,
      height: 20,
      left: -30,
      marginBottom: 4,
    },
  });

  const x = process.env.REACT_APP_SECRET;
  const dispatch = useAuthDispatch();

  return (
    <View style={styles.container}>
      <Formik<FormValues>
        initialValues={{ password: '' }}
        onSubmit={async (values, { resetForm }) => {
          setDisabled(true);
          setLoading(true);
          setDisplayError(false);
          if (values.password && values.password === x) {
            dispatch(process.env.REACT_APP_AUTH_TRUE as string);
            setDisabled(false);
            setLoading(false);
            resetForm({ values: { password: '' } });
          } else if (values.password !== x) {
            setDisplayError(true);
            resetForm({ values: { password: '' } });
            setLoading(false);
            setDisabled(false);
          }
          // console.log('FORM', values.password);
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <>
            <View style={styles.subscribeForm}>
              <TextInput
                autoCorrect={false}
                secureTextEntry={true}
                placeholderTextColor={white}
                onChangeText={(value) => {
                  handleChange('password');
                  setFieldValue('password', value);
                  setDisplayError(false);
                }}
                onBlur={handleBlur('password')}
                value={values.password}
                onSubmitEditing={() => {
                  // console.log('onsubmitediting');
                  setDisplayError(false);
                  handleSubmit();
                }}
                style={[styles.emailInput, {}]}
                placeholder="Enter Password"
              />
              {loading ? <ActivityIndicator /> : null}
              <TouchableOpacity
                // loading={loading}
                disabled={disabled}
                onPress={() => {
                  // console.log('value', values.email);
                  // setDisplayDup(false);
                  setDisplayError(false);
                  if (values.password === null || values.password === '') {
                    setDisplayError(true);
                    return;
                  }
                  handleSubmit();
                }}
              >
                <Image source={submitArrow} style={styles.submitArrow} />
              </TouchableOpacity>
            </View>
            {displayError ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: chartreuse,
                    fontFamily: GTAmericaRegular,
                  }}
                >
                  ***incorrect password***
                </Text>
              </View>
            ) : null}
          </>
        )}
      </Formik>
    </View>
  );
};
