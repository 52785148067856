import React from 'react';
import { Image, View } from 'react-native';
import { blue } from '../../constants/brand';

interface Props {
  height?: number;
  width?: number;
}

export const ShortLogo: React.FC<Props> = React.memo(({ height, width }) => {
  const defaultHeight = 79;
  const defaultWidth = 250;
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <View
        style={{
          height: 3,
          width: 35,
          backgroundColor: blue,
          marginRight: 20,
          marginBottom: 15,
        }}
      />
      <Image
        source={require('../../../assets/images/hmedium@2x.png')}
        style={{
          height: height ? height : defaultHeight,
          width: width ? width : defaultWidth,
          resizeMode: 'contain',
        }}
      />
    </View>
  );
});
