import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';

import { NavBar } from '../nav/NavBar';
import { white } from '../../constants/brand';
import { useAuthState } from '../../context/AuthContext';
import { LoginScreen } from '../../screens/LoginScreen';

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = React.memo(({ children }) => {
  const authState = useAuthState();
  const x = process.env.REACT_APP_AUTH_TRUE;
  if ((authState as any) === 'false') {
    return <LoginScreen />;
  } else {
    return (
      <ScrollView style={{ position: 'relative', zIndex: -1 }}>
        <View style={styles.container}>
          <NavBar />
          {children}
        </View>
      </ScrollView>
    );
  }
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: white,
    minHeight: '100vh',
  },
  text: {
    fontSize: 16,
  },
});
