import React from 'react';
import { View, StyleSheet, Image } from 'react-native';

import { NavLink } from './NavLink';

interface Props {
  linkTitle: string;
  linkPath: string;
}

export const ExternalNavLink: React.FC<Props> = React.memo(
  ({ linkPath, linkTitle }) => {
    return (
      <View style={styles.container}>
        <NavLink linkTitle={linkTitle} linkPath={linkPath} />
        <Image
          source={require('../../../assets/images/arrow-up-right-green@1x.png')}
          style={{
            height: 14,
            width: 14,
            resizeMode: 'contain',
            marginLeft: 4,
          }}
        />
      </View>
    );
  }
);
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    width: 120,
  },
});
