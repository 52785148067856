import React from 'react';
import { View, Text } from 'react-native';
import { useFonts } from '@use-expo/font';

import { wheat, PanamaBold, blue } from '../../constants/brand';
import { useDimensions } from '../../context/DimensionsContext';
import { smMdBreakpoint } from '../../constants/breakpoints';

interface Props {
  text: string;
}

export const TextFooter: React.FC<Props> = React.memo(({ text }) => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../../assets/fonts/Panama-Bold.otf'),
  });

  if (!fontsLoaded) return null;

  return (
    <View
      style={{
        backgroundColor: wheat,
        width: '100vw',
        height: 277,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <Text
        style={{
          fontFamily: PanamaBold,
          fontSize: width < smMdBreakpoint ? 32 : 48,
          letterSpacing: 1.3,
          color: blue,
          alignSelf: 'center',
          textAlign: 'center',
          width: width < smMdBreakpoint ? '80%' : '90%',
        }}
      >
        {text}
      </Text>
    </View>
  );
});
