import React from 'react';

import { Router, Switch, Route, Redirect } from '../navigation/index';
import { LandingScreen } from '../screens/LandingScreen';
import { DeckScreen } from '../screens/DeckScreen';
import { CatalogScreen } from '../screens/CatalogScreen';
import { FAQScreen } from '../screens/FAQScreen';
import { HomeScreen } from '../screens/HomeScreen';
import { CreativityScreen } from '../screens/CreativityScreen';
import { ProductPhilosophyScreen } from '../screens/ProductPhilosophyScreen';
import { MissionScreen } from '../screens/MissionScreen';
import { TeamScreen } from '../screens/TeamScreen';
import { OpportunityScreen } from '../screens/OpportunityScreen';
import { MarketScreen } from '../screens/MarketScreen';
import { CustomersScreen } from '../screens/CustomersScreen';
import { ProductScreen } from '../screens/ProductScreen';
import { GoToMarketScreen } from '../screens/GoToMarketScreen';
import { ProgressScreen } from '../screens/ProgressScreen';
import { StoryScreen } from '../screens/StoryScreen';
import { ContactScreen } from '../screens/ContactScreen';
import { RoadmapScreen } from '../screens/RoadmapScreen';

export function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/story" component={StoryScreen} />
        <Route exact path="/creativity" component={CreativityScreen} />
        <Route exact path="/philosophy" component={ProductPhilosophyScreen} />
        <Route exact path="/mission" component={MissionScreen} />
        <Route exact path="/team" component={TeamScreen} />
        <Route exact path="/opportunity" component={OpportunityScreen} />
        <Route exact path="/market" component={MarketScreen} />
        <Route exact path="/customers" component={CustomersScreen} />
        <Route exact path="/product" component={ProductScreen} />
        <Route exact path="/gotomarket" component={GoToMarketScreen} />
        <Route exact path="/progress" component={ProgressScreen} />
        <Route exact path="/deck" component={DeckScreen} />
        <Route exact path="/catalog" component={CatalogScreen} />
        {/* <Route exact path="/faq" component={FAQScreen} /> */}
        <Route exact path="/contact" component={ContactScreen} />
        <Route exact path="/roadmap" component={RoadmapScreen} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}
