import React, { useState } from 'react';
import {
  Text,
  StyleSheet,
  ScrollView,
  View,
  Linking,
  Image,
} from 'react-native';
import { useFonts } from '@use-expo/font';

import { white, green } from '../../constants/brand';
import { TouchableOpacity } from '../common/TouchableOpacity';

interface Props {
  open: boolean;
  close: any;
}

export const NavDrawer: React.FC<Props> = React.memo(({ open, close }) => {
  const [productsExpand, setProudctsExpand] = useState(false);
  const [deckExpand, setDeckExpand] = useState(false);
  const [aboutExpand, setAboutExpand] = useState(false);
  const [fontsLoaded] = useFonts({
    'GTAmerica-CompressedRegular': require('../../../assets/fonts/GTAmerica-CompressedRegular.otf'),
    'GTAmerica-CompressedMedium': require('../../../assets/fonts/GTAmerica-CompressedMedium.otf'),
  });
  const styles = StyleSheet.create({
    container: {
      zIndex: 10,
      height: '100vh',
      width: 280,
      backgroundColor: white,
      borderColor: green,
      borderWidth: 1,
      position: 'absolute',
      top: 0,
      left: open ? 0 : -100,
      paddingBottom: 50,
    },
    close: {
      marginLeft: 15,
      marginTop: 35,
    },
    nav: {
      fontFamily: 'GTAmerica-CompressedRegular',
      color: green,
      letterSpacing: 1.3,
    },
    primaryNav: {
      fontSize: 36,
      marginBottom: 10,
    },
    secondaryNav: {
      fontSize: 24,
      paddingLeft: 15,
      marginVertical: 5,
    },
  });
  if (!fontsLoaded) return null;
  return (
    <ScrollView style={styles.container}>
      <TouchableOpacity style={styles.close} onPress={() => close(!open)}>
        <Image
          style={{ height: 35, width: 35, resizeMode: 'contain' }}
          source={require('../../../assets/images/close-green.png')}
        />
      </TouchableOpacity>
      <View style={{ marginTop: 40, marginLeft: 10 }}>
        <TouchableOpacity onPress={() => setProudctsExpand(!productsExpand)}>
          <Text style={[styles.nav, styles.primaryNav]}>Products +</Text>
        </TouchableOpacity>
        {productsExpand && (
          <>
            <TouchableOpacity onPress={() => Linking.openURL('/catalog')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Catalog</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/roadmap')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Roadmap</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/philosophy')}>
              <Text style={[styles.nav, styles.secondaryNav]}>
                Our Philosophy
              </Text>
            </TouchableOpacity>
          </>
        )}
        <TouchableOpacity onPress={() => setDeckExpand(!deckExpand)}>
          <Text style={[styles.nav, styles.primaryNav]}>Deck +</Text>
        </TouchableOpacity>
        {deckExpand && (
          <>
            <TouchableOpacity onPress={() => Linking.openURL('/mission')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Mission</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/opportunity')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Opportunity</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/market')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Market</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/product')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Product</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/customers')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Customers</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/gotomarket')}>
              <Text style={[styles.nav, styles.secondaryNav]}>
                Go to Market
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/team')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Team</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/progress')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Progress</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/deck')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Full Deck</Text>
            </TouchableOpacity>
          </>
        )}
        <TouchableOpacity onPress={() => setAboutExpand(!aboutExpand)}>
          <Text style={[styles.nav, styles.primaryNav]}>About +</Text>
        </TouchableOpacity>
        {aboutExpand && (
          <>
            <TouchableOpacity onPress={() => Linking.openURL('/story')}>
              <Text style={[styles.nav, styles.secondaryNav]}>
                Happy Medium
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('/creativity')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Creativity</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity onPress={() => Linking.openURL('/faq')}>
              <Text style={[styles.nav, styles.secondaryNav]}>FAQ</Text>
            </TouchableOpacity> */}
            <TouchableOpacity onPress={() => Linking.openURL('/contact')}>
              <Text style={[styles.nav, styles.secondaryNav]}>Contact</Text>
            </TouchableOpacity>
          </>
        )}
        <TouchableOpacity
          onPress={() => Linking.openURL('https://happy-medium.co')}
        >
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Text style={[styles.nav, styles.primaryNav]}>Play</Text>
            <Image
              style={{
                marginLeft: 8,
                height: 14,
                width: 14,
                resizeMode: 'contain',
              }}
              source={require('../../../assets/images/arrow-up-right-green@1x.png')}
            />
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
});
