import React from 'react';
import { View, Text, Image } from 'react-native';

import {
  blue,
  PanamaBold,
  white,
  PanamaRegular,
  green,
} from '../../constants/brand';
import { Button } from '../common/Button';
import { useDimensions } from '../../context/DimensionsContext';
import {
  smMdBreakpoint,
  mdLgBreakpoint,
  smBreakpoint,
  mdBreakpoint,
} from '../../constants/breakpoints';
import { useFonts } from '@use-expo/font';

interface Props {}

export const HomeProductTeaser: React.FC<Props> = React.memo(() => {
  const { width } = useDimensions();
  const [fontsLoaded] = useFonts({
    'Panama-Bold': require('../../../assets/fonts/Panama-Bold.otf'),
    'Panama-Regular': require('../../../assets/fonts/Panama-Regular.otf'),
  });

  if (!fontsLoaded) return null;

  return (
    <View
      style={{
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: blue,
        width,
      }}
    >
      <Text
        style={{
          fontFamily: PanamaBold,
          color: white,
          fontSize:
            width < smBreakpoint
              ? 24
              : width < smMdBreakpoint
              ? 30
              : width < mdLgBreakpoint
              ? 36
              : 48,
          letterSpacing: 1.3,
          lineHeight:
            width < smMdBreakpoint ? 36 : width < mdLgBreakpoint ? 48 : 52,
          textAlign: 'center',
          marginTop: width < mdBreakpoint ? 30 : 80,
          width: width < mdBreakpoint ? '70%' : '90%',
          alignSelf: 'center',
        }}
      >
        BUSY HANDS MAKE STILL MINDS
      </Text>
      <View
        style={{
          flexDirection: width < mdBreakpoint ? 'column-reverse' : 'row',
          marginTop: width < mdBreakpoint ? 30 : 80,
          alignContent: 'center',
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            width: width < mdBreakpoint ? '100%' : '50%',
            alignItems: 'center',
          }}
        >
          {width > mdBreakpoint && (
            <Image
              style={{ height: 147, width: 226, resizeMode: 'contain' }}
              source={require('../../../assets/images/painting-hand.png')}
            />
          )}
          <Text
            style={{
              fontFamily: PanamaRegular,
              fontSize:
                width < smMdBreakpoint ? 24 : width < mdLgBreakpoint ? 28 : 36,
              letterSpacing: 1.3,
              color: white,
              textAlign: 'center',
              marginVertical: 30,
              width: width < mdBreakpoint ? '90%' : '95%',
            }}
          >
            Meet our first products. We’ve reinvented classic art supplies for
            the modern customer.
          </Text>
          <Button
            // copy="Test"
            image={require('../../../assets/images/arrow-right-white@3x.png')}
            path="/catalog"
            color={white}
          />
          {width <= mdBreakpoint && (
            <Image
              style={{
                marginTop: width < mdBreakpoint ? 30 : 0,
                marginBottom: 20,
                height: 100,
                width: 149,
                resizeMode: 'contain',
                alignSelf: 'baseline',
              }}
              source={require('../../../assets/images/painting-hand.png')}
            />
          )}
        </View>
        <View
          style={{
            width: width < mdBreakpoint ? '100%' : '50%',
            alignItems: width < mdBreakpoint ? 'flex-end' : 'center',
          }}
        >
          <View
            style={{
              backgroundColor: green,
              height:
                width < smMdBreakpoint
                  ? 260
                  : width < mdLgBreakpoint
                  ? 332
                  : 404,
              width:
                width < smMdBreakpoint
                  ? 306
                  : width < mdLgBreakpoint
                  ? 428
                  : 550,
              justifyContent: 'center',
              alignItems: width < mdBreakpoint ? 'flex-end' : 'center',
              alignContent: 'center',
            }}
          >
            <Image
              style={{
                height: '100%',
                width: width < mdBreakpoint ? '90%' : '85%',
                resizeMode: 'contain',
              }}
              source={require('../../../assets/images/watercolor-1.png')}
            />
          </View>
        </View>
      </View>
    </View>
  );
});
