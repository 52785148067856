import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  ImageURISource,
  FlatListProps,
  FlatList,
} from 'react-native';

import {
  catP1,
  catP2,
  catP3,
  catP4,
  catP5,
  catP6,
  catP7,
  catP8,
  catP9,
  catP10,
  catP11,
  catP12,
} from '../constants/links';
import { useDimensions } from '../context/DimensionsContext';
import { white } from '../constants/brand';
import { useAuthState } from '../context/AuthContext';
import {
  smBreakpoint,
  mdBreakpoint,
  lgBreakpoint,
  smMdBreakpoint,
} from '../constants/breakpoints';
import { Layout } from '../components/common/Layout';
import { NavBar } from '../components/nav/NavBar';
import { Redirect } from '../navigation';
import { LoginScreen } from './LoginScreen';

interface Props {}

export const CatalogScreen: React.FC<Props> = React.memo(() => {
  const authState = useAuthState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: white,
    },
    page: {
      width:
        width < smBreakpoint
          ? width
          : width < mdBreakpoint
          ? width / 1.5
          : width < lgBreakpoint
          ? width / 2.9
          : 350,
      height: width < smMdBreakpoint ? height - 100 : height - 200,
      marginHorizontal: 3,
      resizeMode: 'contain',
      marginBottom: 10,
    },
  });
  const x = process.env.REACT_APP_AUTH_TRUE;

  const urls = [
    { uri: catP1 },
    { uri: catP2 },
    { uri: catP10 },
    { uri: catP11 },
    { uri: catP3 },
    { uri: catP4 },
    { uri: catP5 },
    { uri: catP6 },
    { uri: catP7 },
    { uri: catP8 },
    { uri: catP9 },
    { uri: catP12 },
  ];

  const renderItem: FlatListProps<{
    uri: string;
  }>['renderItem'] = ({ item: uri }) => {
    return <Image style={styles.page} source={uri as ImageURISource} />;
  };

  if ((authState as any) === x) {
    return (
      <View style={{ maxWidth: '100vw', zIndex: -1 }}>
        <View style={{ maxWidth: '100vw' }}>
          <NavBar />
        </View>
        <View style={{ zIndex: -1 }}>
          <FlatList
            style={{ backgroundColor: white }}
            data={urls}
            renderItem={renderItem}
            keyExtractor={(item, index) => `${item}-${index}`}
            horizontal={true}
            showsHorizontalScrollIndicator={true}
            initialScrollIndex={width < smBreakpoint ? 0.15 : 0}
          />
        </View>
      </View>
    );
  } else {
    return <LoginScreen />;
  }
});
