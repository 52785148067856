import React, { useState, useContext, useEffect, useMemo } from 'react';
import { usePersistedState } from '../hooks/use-persisted-state';

export type AuthDispatch = (authState: string) => void;

export interface AuthProviderProps {
  children?: React.ReactNode;
}

export interface AuthProviderState {
  authState: string;
}

export const AuthStateContext = React.createContext<AuthProviderState>({
  authState: 'false',
});
export const AuthDispatchContext = React.createContext<
  AuthDispatch | undefined
>((authState) => {
  return null;
});

export function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = usePersistedState('authenticated', 'false');
  // console.log('AUTH', authState);

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={setAuthState}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export function useAuthState() {
  const context = useContext(AuthStateContext);
  // console.log('CONTEXT', context);
  if (context === undefined) {
    throw new Error('useAuthStateError');
  }
  // console.log('CONTEXTII', context);
  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatchError');
  }
  return context;
}

export function useAuthContext() {
  return [useAuthState(), useAuthDispatch()];
}
