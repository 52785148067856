import React, { useState } from 'react';
import { View } from 'react-native';
import { Hoverable, useDimensions } from 'react-native-web-hooks';

import { NavLink } from './NavLink';
import { xlBreakpoint } from '../../constants/breakpoints';

interface Props {
  linkTitle: string;
  linkPath: string;
  unfurlMenu: React.ReactNode;
}

export const HoverableNavLink: React.FC<Props> = React.memo(
  ({ linkTitle, linkPath, unfurlMenu }) => {
    const [showMenu, setShowMenu] = useState(false);
    const {
      window: { width },
    } = useDimensions();

    return (
      <>
        <Hoverable
          onHoverIn={() => {
            setShowMenu(true);
            console.log('HOVER IN');
          }}
          onHoverOut={() => setShowMenu(false)}
        >
          {() => (
            <View style={{ height: '100%', width: 120, alignItems: 'center' }}>
              <NavLink linkPath={linkPath} linkTitle={linkTitle} />
            </View>
          )}
        </Hoverable>
        <Hoverable
          onHoverIn={() => setShowMenu(true)}
          onHoverOut={() => setShowMenu(false)}
        >
          {() => (
            <View
              style={{
                position: 'absolute',
                right: 0,
                top: width < xlBreakpoint ? 120 : 150,
                zIndex: 10,
              }}
            >
              {showMenu && unfurlMenu}
            </View>
          )}
        </Hoverable>
      </>
    );
  }
);
