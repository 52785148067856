import React from 'react';
import { View, Image } from 'react-native';

import { white } from '../../constants/brand';

interface Props {}

export const CreativityFooter: React.FC<Props> = React.memo(() => {
  return (
    <View
      style={{
        backgroundColor: white,
        width: '100vw',
        height: 277,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <Image
        style={{ height: 168, width: 100, resizeMode: 'contain' }}
        source={require('../../../assets/images/you-made-it.png')}
      />
    </View>
  );
});
