import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useFonts } from '@use-expo/font';

import { white, green, blue } from '../../../constants/brand';
import { ShortLogo } from '../../common/ShortLogo';
import { MenuItem } from './MenuItem';

interface Props {}

export const ProductsMenu: React.FC<Props> = React.memo(() => {
  const styles = StyleSheet.create({
    quote: {
      fontFamily: 'Panama-Regular',
      color: blue,
      textTransform: 'uppercase',
      fontSize: 36,
      letterSpacing: 1.3,
      marginVertical: 15,
    },
  });

  const [fontsLoaded] = useFonts({
    'Panama-Regular': require('../../../../assets/fonts/Panama-Regular.otf'),
  });

  if (!fontsLoaded) return null;

  return (
    <View
      style={{
        position: 'absolute',
        marginTop: 50,
        right: 0,
        width: '100vw',
        height: 450,
        backgroundColor: white,
        zIndex: 10,
        borderWidth: 1,
        borderColor: green,
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          left: '4.5%',
          marginTop: 10,
        }}
      >
        <MenuItem title="Catalog" path="/catalog" />
        <MenuItem title="Roadmap" path="/roadmap" />
        <MenuItem title="Our Philosophy" path="/philosophy" />
      </View>
      <View style={{ position: 'absolute', right: 180, marginTop: 80 }}>
        <View style={{ alignItems: 'center' }}>
          <Text style={styles.quote}>
            THE ONLY DANGER IN GIVING ART A CHANCE
          </Text>
          <Text style={styles.quote}>IS EXPANDING YOUR UNIVERSE.</Text>
        </View>
        <View style={{ position: 'absolute', right: 0, marginTop: 180 }}>
          <ShortLogo />
        </View>
      </View>
    </View>
  );
});
