import React from 'react';
import { Video } from 'expo-av';
import { useDimensions } from '../../context/DimensionsContext';
import { smMdBreakpoint } from '../../constants/breakpoints';

interface Props {}

export const VideoPlayer: React.FC<Props> = () => {
  const { width } = useDimensions();
  const x = require('../../../');
  return (
    <Video
      source={{
        uri:
          'https://pitchdeckme.s3.amazonaws.com/Investor+Site+2.0/HappyMedium_02+-+no+early+access.mp4',
      }}
      rate={1.0}
      volume={1.0}
      isMuted={false}
      usePoster={true}
      posterSource={require('../../../assets/images/logo-green@200x.png')}
      posterStyle={{
        height: '50%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        marginTop: width < smMdBreakpoint ? 0 : 60,
      }}
      resizeMode="contain"
      useNativeControls={true}
      style={{
        width: width < smMdBreakpoint ? '99%' : 500,
        height: width < smMdBreakpoint ? 180 : 280,
        alignSelf: 'center',
        marginVertical: 10,
      }}
    />
  );
};
