import React, { useState } from 'react';
import { View, Image, Linking, StyleSheet } from 'react-native';

import { TouchableOpacity } from '../common/TouchableOpacity';
import { Logo } from '../common/Logo';
import { NavDrawer } from './NavDrawer';

interface Props {}

export const MobileNavBar: React.FC<Props> = React.memo(() => {
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const styles = StyleSheet.create({
    mobileContainer: {
      flexDirection: 'row',
      height: 100,
      alignItems: 'center',
      width: '100%',
    },
  });
  return (
    <View style={styles.mobileContainer}>
      {navDrawerOpen ? (
        <NavDrawer close={setNavDrawerOpen} open={navDrawerOpen} />
      ) : null}
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TouchableOpacity
          style={{ position: 'absolute', left: 0 }}
          onPress={() => setNavDrawerOpen(!navDrawerOpen)}
        >
          <Image
            source={require('../../../assets/images/hamburger-green.png')}
            style={{
              width: 25,
              height: 25,
              resizeMode: 'contain',
              marginLeft: 20,
              left: 0,
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => Linking.openURL('/')}>
          <Logo width={250} height={60} />
        </TouchableOpacity>
      </View>
    </View>
  );
});
